<template>
  <!-- <div class="container">
    <div class="col-12 py-4">
      <CountryList />
    </div>
  </div> -->
  <router-view></router-view>
</template>

<script>
// import CountryList from './components/CountryList.vue';

export default {
  name: 'Country',
  // components:{
  //   CountryList,
  // },
};

</script>
